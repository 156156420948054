@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'proximanova-Light';
    src: font('proximanova-light.woff2') format('woff2'),
         font('proximanova-light.woff') format('woff'),
         font('proximanova-light.otf') format('otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-regular';
    src: font('proximanova-regular.woff2') format('woff2'),
         font('proximanova-regular.woff') format('woff'),
         font('proximanova-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-medium';
    src: font('proximanova-medium.eot');
    src: font('proximanova-medium.eot') format('embedded-opentype'),
         font('proximanova-medium.woff2') format('woff2'),
         font('proximanova-medium.woff') format('woff'),
         font('proximanova-medium.ttf') format('truetype'),
         font('proximanova-medium.svg#proximanova-medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-semibold';
    src: font('proximanova-semibold.eot');
    src: font('proximanova-semibold.eot') format('embedded-opentype'),
         font('proximanova-semibold.woff2') format('woff2'),
         font('proximanova-semibold.woff') format('woff'),
         font('proximanova-semibold.ttf') format('truetype'),
         font('proximanova-semibold.svg#proximanova-semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-bold';
    src: font('proximanova-bold.woff2') format('woff2'),
         font('proximanova-bold.woff') format('woff'),
         font('proximanova-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-extrabold';
    src: font('proximanova-extrabold.woff2') format('woff2'),
         font('proximanova-extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-black';
    src: font('proximanova-black.woff2') format('woff2'),
         font('proximanova-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}