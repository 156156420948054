.comingSoon {
    $comingSoon: ".comingSoon";

    &__main {

        #{$comingSoon}__header {

            .logo {
                display: block;
                width: 25%;
                margin: 0 auto;
                margin-bottom: 30px;

                img {
                    width: 100%;
                }
            }
        }

        #{$comingSoon}__body {
            text-align: center;

            h1 {
                font-family: $secondary_font;
                font-weight: 700;
                font-size: 45px;
                line-height: 1.2;
                color: #025017;
                margin-bottom: 20px;

                @media (max-width: $mobileBig) {
                    font-size: 35px;
                }
            }

            p {
                font-size: 14px;
                line-height: 1.2;
                margin-bottom: 30px;
            }
        }

        #{$comingSoon}__footer {

            form {
                width: 75%;
                margin: 0 auto;

                .form-group {
                    display: flex;

                    position: relative;
                    border-radius: 30px;
                    overflow: hidden;
                    box-shadow: 0 0 32px 1px rgba($black_color, 0.09);

                    .form-control {
                        border: 0;
                        padding-right: 120px;
                        padding-left: 20px;

                        height: 40px;

                        &::-webkit-input-placeholder {
                            font-family: $primary_normal;
                            font-family: 400;
                            font-size: 16px;
                            line-height: 1.2;
                            color: #afafaf;
                        }

                        &::-moz-placeholder {
                            font-family: $primary_normal;
                            font-family: 400;
                            font-size: 16px;
                            line-height: 1.2;
                            color: #afafaf;
                        }

                        &:-ms-input-placeholder {
                            font-family: $primary_normal;
                            font-family: 400;
                            font-size: 16px;
                            line-height: 1.2;
                            color: #afafaf;
                        }

                        &:-moz-placeholder {
                            font-family: $primary_normal;
                            font-family: 400;
                            font-size: 16px;
                            line-height: 1.2;
                            color: #afafaf;
                        }
                    }

                    .btn-primary {
                        position: absolute;
                        top: 0;
                        right: 0;

                        font-family: $primary_semi;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 1.2;
                        background-color: $white_color;
                        background-color: $primary_color;
                        padding: 12px 20px;

                        &:hover {
                            background-color: $primary_hover;
                        }
                    }
                }
            }
        }
    }
}