// Variables
$primary_color      : #025017;
$primary_hover      : #023610;
$secondary_color    : #ffffff;
$mainMenu_Color     : #242424;
$heading_color      : #232323;
$heading_color2		: #333333;
$light_color        : #ecf2f7;
$light_blue 		: #01b9f5;
$light_green 		: #71d875;
$light_orange		: #f99240;
$white_color		: #ffffff;
$yellow_color		: #edc317;
$black_color		: #000000;
$warning			: #edc317;
$green 				: #71d875;

// Fonts
$primary_light  	: 'proximanova-Light';
$primary_normal 	: 'proximanova-regular';
$primary_medium 	: 'proximanova-medium';
$primary_semi  		: 'proximanova-semibold';
$primary_bold  		: 'proximanova-bold';
$primary_extrabold  : 'proximanova-extrabold';
$primary_black  	: 'proximanova-black';
$secondary_font     : 'Poppins', sans-serif;

// Viewports
$desktop_xxl	:	1600px;
$desktop_xl		:	1440px;
$laptop			:	1366px;
$desktop		:	1280px;
$desktop2		:	1199px;
$desktopSmall	:	1024px;
$tablet			:	992px;
$tablet2		:	991px;
$tabletSmall	:	768px;
$mobileBig		:	767px;
$mobileMed		:	576px;
$mobile			:	480px;
$mobileSmall	:	375px;
$mobilexs      	:	320px;


// New Variables
// COLORS
$color-primary: $primary_color;
$color-primary-light: lighten($primary_color, 0.75);
$color-primary-dark: darken($primary_color, 1.25);

$color-secondary: $secondary_color;
$color-secondary-light: lighten($secondary_color, 0.75);
$color-secondary-dark: darken($secondary_color, 1.25);

$color-tertiary: #121213;

$color-skyblue-light: #f4f9ff;

$color-grey-light-1: #abadb0;
$color-grey-light-2: #f1f1f1;
$color-grey-light-3: #f8f7fd;

$color-grey-dark: #1d2630;
$color-grey-dark-2: #666;
$color-grey-dark-3: #333;

$color-green: #00a900;
$color-green-dark: #248e2e;

$color-green2: #38c172;

$color-red: #ff0000;
$color-red-dark: #ff0000;

$color-white: #fff;
$color-black: #000;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;
$gutter-vertical-small: 6rem;

// VIEWPORTS
// $macbook: 1440px;
// $laptop: 1366px;
// $small-laptop: 1280px;
// $tablet-big: 1024px;
// $tablet: 992px;
// $tablet-small: 991px;
// $mobile-big: 767px;
// $mobile: 600px;
// $mobile-medium: 480px;
// $mobile-small: 375px;
// $mobile-extra-small: 320px;

.display-flex {
    display: flex !important;
}

.flex-warp-warp {
    flex-wrap: wrap !important;
}

.mobileview-none {
    display: block !important;
}

.mobileview-block {
    display: none !important;
}

.laptopview-block {
    display: block !important;
}

.laptopview-none {
    display: none !important;
}

@media (max-width: $desktop - 1) {

    .laptopview-block {
        display: none !important;
    }

    .laptopview-none {
        display: block !important;
    }
}

@media (max-width: $desktopSmall - 1) {

    .margin-bottom-xs-30 {
        margin-bottom: 30px !important;
    }

    .mobileview-none {
        display: none !important;
    }

    .mobileview-block {
        display: block !important;
    }
}

@media (max-width: $mobileBig) {
    .text-xs-center {
        text-align: center !important;
    }
}